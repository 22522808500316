module.exports = [
  'Salt Lake City',
  'West Valley City',
  'Provo',
  'West Jordan',
  'Orem',
  'Sandy',
  'St George',
  'Ogden',
  'Layton',
  'South Jordan',
  'Lehi',
  'Millcreek',
  'Taylorsville',
  'Herriman',
  'Logan',
  'Murray',
  'Draper',
  'Riverton',
  'Bountiful',
  'Eagle Mountain',
  'Spanish Fork',
  'Roy',
  'Pleasant Grove',
  'Kearns',
  'Saratoga Springs',
  'Tooele',
  'Midvale',
  'Cedar City',
  'American Fork',
  'Cottonwood Heights',
  'Springville',
  'Kaysville',
  'Clearfield',
  'Syracuse',
  'Holladay',
  'Washington',
  'Magna',
  'Farmington',
  'South Salt Lake',
  'Clinton',
  'North Ogden',
  'North Salt Lake',
  'Hurricane',
  'Highland',
  'Payson',
  'Brigham City',
  'Centerville',
  'Bluffdale',
  'Heber',
]